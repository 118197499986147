import React from 'react';
import { Table, TableRow, TableCell } from '@material-ui/core';
import { Buttons } from '../../../../components/Buttons';
import { formatCurrency } from '../../../../helpers/commonFunction';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import classes from '../Payment.module.scss';
import TableBody from '@material-ui/core/TableBody';
import PropTypes from 'prop-types';

function PaymentDetails(props) {
  const getTotalName = () => {
    if (props.locationSettings?.DualFeeEnabled) {
      if (props.selectedOption === 'card') return 'Card Total';
      else return 'Cash Total';
    } else {
      return 'Total';
    }
  };
  return (
    <Grid item md={4} lg={3}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Buttons
                id="vInvoice"
                type="button"
                fullWidth={true}
                className={classes.viewInvoice}
                variant="outlined"
                onClick={props.onClick || (() => {})}
                text={props.ButtonText}
              />
            </Grid>
            <Grid item xs={12} className={classes.invoiceDetail}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableBody>
                  {props.invoice.length > 0 && (
                    <TableRow>
                      <TableCell align="left">Invoice Number</TableCell>
                      <TableCell align="right" style={{ overflowWrap: 'anywhere' }}>
                        {props.invoice}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell align="left">Customer Name</TableCell>
                    <TableCell align="right" style={{ overflowWrap: 'anywhere' }}>
                      {`${props.customerData.firstName} ${props.customerData.lastName}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">Amount</TableCell>
                    <TableCell align="right">{formatCurrency(props.amount)}</TableCell>
                  </TableRow>
                  {props.locationSettings?.virtualTerminalSettings?.InvoiceDiscount &&
                    !props.locationSettings?.DualFeeEnabled &&
                    props.selectedOption === 'card' && (
                      <TableRow>
                        <TableCell align="left">
                          {props.locationSettings?.virtualTerminalSettings?.DiscountFeeName
                            ? props.locationSettings?.virtualTerminalSettings?.DiscountFeeName
                            : 'Svc Fee'}
                        </TableCell>
                        <TableCell align="right">{formatCurrency(props.fees.svcFee)}</TableCell>
                      </TableRow>
                    )}
                  {props.locationSettings?.virtualTerminalSettings?.InvoiceSurcharge &&
                    props.selectedOption === 'card' &&
                    props.paymentType &&
                    props.paymentType.toUpperCase() !== 'DEBIT' && (
                      <TableRow>
                        <TableCell align="left">Surcharge</TableCell>
                        <TableCell align="right">{formatCurrency(props.fees.surcharge)}</TableCell>
                      </TableRow>
                    )}
                  {props.locationSettings?.virtualTerminalSettings?.InvoiceTax && (
                    <TableRow>
                      <TableCell align="left">Sales Tax</TableCell>
                      <TableCell align="right">{formatCurrency(props.fees.tax)}</TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell align="left">
                      <b>{getTotalName()}</b>
                    </TableCell>
                    <TableCell align="right">
                      <b>{props.total ? formatCurrency(props.total) : '$0.00'}</b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

PaymentDetails.propTypes = {
  locationSettings: PropTypes.shape({
    DualFeeEnabled: PropTypes.any,
    virtualTerminalSettings: PropTypes.shape({
      InvoiceDiscount: PropTypes.any,
      DiscountFeeName: PropTypes.any,
      InvoiceSurcharge: PropTypes.any,
      InvoiceTax: PropTypes.any,
    }),
  }),
  selectedOption: PropTypes.any,
  onClick: PropTypes.any,
  ButtonText: PropTypes.any,
  invoice: PropTypes.shape({
    length: PropTypes.any,
  }),
  customerData: PropTypes.shape({
    firstName: PropTypes.any,
    lastName: PropTypes.any,
  }),
  amount: PropTypes.any,
  fees: PropTypes.shape({
    svcFee: PropTypes.any,
    surcharge: PropTypes.any,
    tax: PropTypes.any,
  }),
  paymentType: PropTypes.any,
  total: PropTypes.any,
};

export default PaymentDetails;
